export default {
  props: {
    variant: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultClass: 'text-gray-700 bg-gray-200 dark:bg-gray-800 dark:text-white/80 hover:bg-gray-300 dark:border-gray-900',
      infoClass: 'text-white bg-blue-600 dark:bg-blue-800 border-blue-400 hover:bg-blue-700',
      successClass: 'text-white bg-green-500 dark:bg-green-700 border-green-400 hover:bg-green-700',
      dangerClass: 'text-white bg-red-500 dark:bg-red-700 border-red-500 hover:bg-red-600 hover:border-red-600',
      warningClass: 'text-white bg-orange-500 dark:bg-orange-700 border-orange-500 hover:bg-orange-600 hover:border-orange-600',
      lightClass: 'text-white bg-gray-100 hover:bg-gray-200 hover:text-gray-700',
      darkClass: 'text-white bg-gray-700 hover:bg-gray-700',
      primaryClass: 'text-white bg-primary-600 dark:bg-primary-800 hover:bg-opacity-85',
      secondaryClass: 'text-white bg-secondary hover:bg-opacity-85',

      defaultFlatClass: 'text-gray-600 bg-gray-100 dark:bg-white/5 dark:text-white/80 hover:bg-gray-200 hover:text-gray-700',
      infoFlatClass: 'bg-blue-200 dark:bg-blue-800/20 text-blue-700 dark:text-blue-500 hover:bg-blue-600 hover:text-white',
      successFlatClass: 'bg-green-100 dark:bg-green-800/20 text-green-700 dark:text-green-500 hover:bg-green-600 hover:text-white',
      dangerFlatClass: 'bg-red-200 dark:bg-red-800/20 text-red-700 dark:text-red-500 hover:bg-red-600 hover:text-white',
      warningFlatClass: 'bg-orange-100 dark:bg-orange-800/20 text-orange-700 dark:text-orange-500 hover:bg-orange-600 hover:text-white',
      lightFlatClass: 'bg-gray-100 dark:bg-blue-800/20 text-gray-700 dark:text-gray-200 hover:bg-gray-200 hover:text-gray-700',
      darkFlatClass: 'bg-gray-100 dark:bg-blue-800/20 text-gray-700 dark:text-gray-200 hover:bg-gray-600 hover:text-white',
      primaryFlatClass: 'bg-primary-200 dark:bg-primary-800/20 text-primary-700 dark:text-primary-500 hover:bg-primary-600 hover:text-white',

      defaultOutlineClass: 'border border-gray-300 dark:border-gray-700 text-gray-600 dark:text-white dark:text-gray-300',
      infoOutlineClass: 'border border-blue-400 dark:border-blue-800 text-blue-600',
      successOutlineClass: 'border border-green-400 dark:border-green-800 text-green-600',
      dangerOutlineClass: 'border border-red-400 dark:border-red-800 text-red-600',
      warningOutlineClass: 'border border-orange-400 dark:border-orange-800 text-orange-500',
      lightOutlineClass: 'border border-gray-50 dark:border-gray-800 text-gray-50',
      darkOutlineClass: 'border border-gray-800 dark:border-gray-800 text-gray-800',
      primaryOutlineClass: 'border border-primary-400 dark:border-primary-800 text-primary-600 dark:text-primary-400',
    }
  },
  methods: {
    variants (classes) {
      if (!this.flat && !this.outline) {
        switch (this.variant) {
          case 'info':
            classes.push(this.infoClass)
            break
          case 'danger':
            classes.push(this.dangerClass)
            break
          case 'warning':
            classes.push(this.warningClass)
            break
          case 'success':
            classes.push(this.successClass)
            break
          case 'light':
            classes.push(this.lightClass)
            break
          case 'dark':
            classes.push(this.darkClass)
            break
          case 'primary':
            classes.push(this.primaryClass)
            break
          case 'secondary':
            classes.push(this.secondaryClass)
            break
          default:
            classes.push(this.defaultClass)
            break
        }
      }
      if (this.flat) {
        switch (this.variant) {
          case 'info':
            classes.push(this.infoFlatClass)
            break
          case 'danger':
            classes.push(this.dangerFlatClass)
            break
          case 'warning':
            classes.push(this.warningFlatClass)
            break
          case 'success':
            classes.push(this.successFlatClass)
            break
          case 'light':
            classes.push(this.lightFlatClass)
            break
          case 'dark':
            classes.push(this.darkFlatClass)
            break
          case 'primary':
            classes.push(this.primaryFlatClass)
            break
          default:
            classes.push(this.defaultFlatClass)
            break
        }
      }
      if (this.outline) {
        switch (this.variant) {
          case 'info':
            classes.push(this.infoOutlineClass)
            break
          case 'danger':
            classes.push(this.dangerOutlineClass)
            break
          case 'warning':
            classes.push(this.warningOutlineClass)
            break
          case 'success':
            classes.push(this.successOutlineClass)
            break
          case 'light':
            classes.push(this.lightOutlineClass)
            break
          case 'dark':
            classes.push(this.darkOutlineClass)
            break
          case 'primary':
            classes.push(this.primaryOutlineClass)
            break
          default:
            classes.push(this.defaultOutlineClass)
            break
        }
      }
      return classes
    }
  }
}
